.container {
    max-width: 450px;
}

.btn-outline-secondary {
    border-color: #ced4da;
    color: #212529;
}

.input-error {
    border-color: var(--bs-danger) !important;
    border-width: 2px;
    box-shadow: none !important;
    outline: 0 none;
}

.error_message {
    color: var(--bs-danger) !important;
    font-weight: 600;
}

.input_length {
    text-align: end;
    font-weight: 500;
    margin-top: 6px;
}

.error_input_length {
    color: var(--bs-danger);
}

.success_input_length {
    color: var(--bs-success);
}

.incorrect-fields,
.submitted-form-screen {
    text-align: center;
    height: calc(100vh - 6rem);
    display: flex;
    align-items: center;
    justify-content: center;
}

.errorModal {
    display: block;
}

.submit-button {
    width: 117px;
}
